@import '@hiredigital/ui/variables';
@import '../variables';

.button {
  margin: 10px;
  display: inline-block;
  border-radius: 999px;
  padding: 15px 25px;
  min-width: 200px;
  text-decoration: none;
  color: var(--accentColorSecondaryBackground, $accentBackground2);
  background-color: var(--accentColorPrimaryBackground, $accentBackground1);
  border: 2px solid var(--accentColorPrimaryBackground, $accentBackground1);
  font-weight: $fw-3;

  &.secondary {
    color: var(--accentColorSecondaryText, $white);
    background-color: transparent;
    border: 2px solid var(--accentColorSecondaryText, $white);
  }

  .arrow {
    float: right;
    rotate: 45deg;
    width: 18px;
    height: 18px;
    transition: transform 0.3s $cubic;
  }

  &:hover {
    .arrow {
      transform: translateY(-4px) translateX(4px);
    }
  }
}
